import { ChangeDetectionStrategy, Component, ViewChild, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';

import {
	IonBackButton,
	IonBadge,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonNote,
	IonRouterLink,
	IonRouterOutlet,
	IonTitle,
	IonToolbar,
	MenuController,
	ViewWillLeave,
} from '@ionic/angular/standalone';
import { TranslocoPipe } from '@ngneat/transloco';
import { filter, fromEvent, map, merge, mergeMap, tap } from 'rxjs';

import { ProjectEvent } from '@sulser-print/constants/events/project.events';
import { ApiParameter } from '@sulser-print/constants/parameters';
import { Project } from '@sulser-print/models/project/project';
import { IonIconPipe } from '@sulser-print/pipes/icon/ion-icon.pipe';
import { PrintJobsService } from '@sulser-print/services/print-jobs.service';
import { PrintJobsStore } from '@sulser-print/services/print-jobs.store';
import { ProjectsService } from '@sulser-print/services/project/projects.service';
import { QuickFillingService } from '@sulser-print/services/quick-filling.service';
import { QuickFillingStore } from '@sulser-print/services/quick-filling.store';

import { ProjectMenuComponent } from './ui/project-menu/project-menu.component';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		IonHeader,
		IonToolbar,
		IonBackButton,
		IonButtons,
		IonButton,
		IonIcon,
		IonTitle,
		IonContent,
		IonNote,
		IonItem,
		IonList,
		IonRouterOutlet,
		IonRouterLink,
		RouterLink,
		IonMenu,
		IonMenuToggle,
		RouterLinkActive,
		IonBadge,
		IonIconPipe,
		TranslocoPipe,
		ProjectMenuComponent,
	],
	providers: [PrintJobsStore, PrintJobsService, QuickFillingService, QuickFillingStore, ProjectMenuComponent],
	selector: 'app-project',
	standalone: true,
	styleUrl: './project.page.scss',
	templateUrl: './project.page.html',
})
export class ProjectPage implements ViewWillLeave {
	private readonly quickFillingService = inject(QuickFillingService);

	readonly activeProject = signal<Project | undefined>(undefined);

	@ViewChild(ProjectMenuComponent, { static: true }) readonly projectMenuComponent?: ProjectMenuComponent;

	constructor(
		private readonly projectsService: ProjectsService,
		protected readonly activatedRoute: ActivatedRoute,
		private readonly printJobsService: PrintJobsService,
		private readonly menuController: MenuController,
	) {
		this.activatedRoute.params
			.pipe(
				takeUntilDestroyed(),
				mergeMap((parameters) => this.projectsService.getById(+parameters[ApiParameter.PROJECT_ID])),
				map((response) => response.data),
				filter((project): project is Project => !!project),
				tap((project) => this.activeProject.set(project)),
				mergeMap((project) =>
					merge(this.printJobsService.getProjectPrintJobIds(project.id), this.quickFillingService.readFromProject(project.id)),
				),
			)
			.subscribe();

		fromEvent(window, ProjectEvent.PROJECT_UPDATED)
			.pipe(
				takeUntilDestroyed(),
				filter(
					(event): event is CustomEvent<Project> => event instanceof CustomEvent && event.detail.id === this.activeProject()?.id,
				),
			)
			.subscribe((project) => {
				this.activeProject.update((currentProject) => ({ ...currentProject, ...project.detail }));
			});
	}

	ionViewWillLeave() {
		this.menuController.enable(false, this.projectMenuComponent?.menuId).then((menu) => menu?.close());
	}
}
